import { FRAME_THEME_COLOR } from '@nandosaus/constants';
import { get, trimStart } from 'lodash';
import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import PropTypes from 'prop-types';

import { useBrazeImpression } from '@hooks/use-braze-impression';
import { BackgroundFrame } from '../../components/frames/background-frame';
import { Box } from '../../components/grid';
import { ContentMatrix } from '../../components/content-matrix';
import { decals } from '../../components/decal/constants';
import { floodlightPageLoadEventPropTypes } from '../../prop-types';
import { Layout } from '../../components/layout';
import { makeInitialProps } from '../../utils/make-initial-props';
import { navigation } from '../../constants';
import { P } from '../../components/typography';
import { PageHeaderBlock } from '../../components/content-image';
import { PeriPerksAppStore } from '../../components/peri-perks-app-store';
import { seoPropDefaults, seoPropTypes } from '../../utils/extract-seo-meta-from-entry';
import { useFeature } from '../../hooks/use-feature';

const JOIN_PERKS_BUTTON_LINK = [
  {
    buttonText: 'Join PERi-Perks',
    link: [
      {
        typeHandle: 'internalLink',
        internalLink: [
          {
            uri: trimStart(navigation.CREATE_ACCOUNT, '/'),
          },
        ],
      },
    ],
  },
];

const useBrazeContent = ({ heading, subheading }) => {
  const { BrazeStore } = useRootStore();

  const { getLatestContentCardExtraByType } = BrazeStore;
  const card = getLatestContentCardExtraByType('perks-signed-in');
  if (!card) {
    return { heading, subheading };
  }

  return {
    heading: get(card, 'heading', null),
    subheading: get(card, 'subheading', null),
    brazeLogImpression: card?.brazeLogImpression,
  };
};

const PeriPerks = ({ metaContent, floodlightPageLoadEvent, contentMatrix }) => {
  const { MemberStore } = useRootStore();

  const { isSignedIn } = MemberStore;

  const isDigitalWalletEnabled = useFeature('digital-wallet');

  const { SALMON } = FRAME_THEME_COLOR;

  const content = {
    heading: "Love Nando's?\nLet us love you back",
    subheading: (
      <>
        <P variant={1} mt={2} pt={{ xs: 1, sm: 3, md: 0 }} keepMargins>
          Eat up. Earn Points. Reward your appetite with exclusive freebies, discounts and events!
        </P>
        {isSignedIn && isDigitalWalletEnabled && (
          <P variant={1} mt={3}>
            Download your peri perks card using the app link below and collect points with your phone.
          </P>
        )}
      </>
    ),
  };

  const { heading, subheading, brazeLogImpression } = useBrazeContent(content);

  useBrazeImpression({ brazeLogImpression });

  return (
    <Layout title="PERi-Perks" metaContent={metaContent} floodlightPageLoadEvent={floodlightPageLoadEvent}>
      <BackgroundFrame variant={SALMON} padding="0" showTopBar={false}>
        <Box
          style={{
            width: '100%',
          }}
        >
          <PageHeaderBlock
            heading={heading}
            subheading={subheading}
            backgroundColor="dark"
            leftDecal={decals['peri-perks'].left}
            rightDecal={decals['peri-perks'].right}
            contentPosition="center"
            contentAlignment="center"
            button={!isSignedIn ? JOIN_PERKS_BUTTON_LINK : undefined}
          />
        </Box>
      </BackgroundFrame>

      <ContentMatrix blocks={contentMatrix} />

      <PeriPerksAppStore />
    </Layout>
  );
};

PeriPerks.getInitialProps = makeInitialProps('peri-perks');

PeriPerks.propTypes = {
  metaContent: PropTypes.shape(seoPropTypes),
  floodlightPageLoadEvent: floodlightPageLoadEventPropTypes,
  contentMatrix: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

PeriPerks.defaultProps = {
  metaContent: seoPropDefaults,
  floodlightPageLoadEvent: undefined,
};

export default observer(PeriPerks);
