import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { fetchCMSContent } from './fetch-cms-content';
import { fetchStaticRedirects } from './fetch-static-redirects';
import { handleRegistrationCallback } from './handle-registration-callback';
import { REGISTRATION_PAGE } from '../constants';
import { setCacheHeaders } from './set-cache-headers';
import { getFeatureFlags } from './feature-flags';

const makeInitialProps = page => async ({ query, res }) => {
  const preview = get(query, 'x-craft-live-preview');
  const token = get(query, 'token');
  const slug = page || get(query, 'slug', '');
  const child = get(query, 'child');
  const uri = `${slug}${child ? `/${child}` : ''}`;
  const code = get(query, 'Code');
  const featureFlags = await getFeatureFlags();

  if (res) {
    const retour = await fetchStaticRedirects(uri);

    if (retour && retour.enabled) {
      res.writeHead(retour.redirectHttpCode, {
        Location: retour.redirectDestUrl,
      });
      res.end();
      return {};
    }
    const isRegistration = uri === REGISTRATION_PAGE && !!code;
    if (isRegistration) {
      const { success, errorMessage } = await handleRegistrationCallback(code);
      if (!success) {
        return { statusCode: 400, errorMessage, featureFlags };
      }

      res.writeHead(301, {
        Location: `/${REGISTRATION_PAGE}`,
      });
      res.end();
      return {};
    }
  }

  const { id, title, contentMatrix, metaContent, parent, floodlightPageLoadEvent } = await fetchCMSContent({
    uri,
    child,
    slug,
    preview,
    token,
  });

  if (res) {
    if (isNil(id) && !page) {
      // eslint-disable-next-line no-param-reassign
      res.statusCode = 404;
      return { statusCode: 404, featureFlags };
    }

    const isPreview = preview && token;
    setCacheHeaders({ res, isPreview });
  }

  return { title, contentMatrix, metaContent, parent, floodlightPageLoadEvent, featureFlags };
};

export { makeInitialProps };
