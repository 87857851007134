import { ANALYTICS_EVENTS, APP_STORE_URLS, MARKET, PLAY_STORE_URLS } from '@nandosaus/constants';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { analytics } from '../../analytics';
import { breakpoints, navigation } from '../../constants';
import { Container, Flex } from '../grid';
import { H2, P, Subheading } from '../typography';
import { Link } from '../link';
import { useMarket } from '../../hooks/use-market';

const PeriPerksAppStore = ({ showCreateAccountLink }) => {
  const market = useMarket();
  const isNzMarket = market === MARKET.NEW_ZEALAND;

  const APP_STORE_URL = isNzMarket ? APP_STORE_URLS.NZ : APP_STORE_URLS.AU;
  const GOOGLE_PLAY_URL = isNzMarket ? PLAY_STORE_URLS.NZ : PLAY_STORE_URLS.AU;

  return (
    <Container mb={1}>
      <Flex my={4} flexWrap="wrap">
        <Flex backgroundColor="white" mb="0.5rem" width={{ xs: 12 / 12, lg: 6 / 12 }} flex={1}>
          <Flex alignItems="center" flexDirection="column" pt={3} px={3} pb={4} textAlign="center">
            <H2 mb={2}>How it works</H2>

            <Subheading variant={3}>Join PERi-Perks</Subheading>

            <P variant={2} mt={1}>
              Download the Nando’s app (recommended for maximum Perks)
            </P>
            {showCreateAccountLink && (
              <P variant={2}>
                or{' '}
                <Link
                  mb={1}
                  href={navigation.CREATE_ACCOUNT}
                  variant={2}
                  onClick={() =>
                    analytics.track(ANALYTICS_EVENTS.USER_SIGN_UP_CLICKED, { location: 'Peri-Perks Store' })
                  }
                >
                  create an account online
                </Link>
              </P>
            )}

            <Flex mb={2} mt={2}>
              <Link
                href={APP_STORE_URL}
                target="_blank"
                onClick={() => analytics.track(ANALYTICS_EVENTS.APPLICATION_INSTALL_CLICKED, { store: 'Apple' })}
              >
                <AppBadge alt="app-store-badge" src="static/images/peri-perks/app-store-badge.svg" />
              </Link>

              <Link
                href={GOOGLE_PLAY_URL}
                target="_blank"
                onClick={() => analytics.track(ANALYTICS_EVENTS.APPLICATION_INSTALL_CLICKED, { store: 'Google Play' })}
              >
                <AppBadge alt="google-play-badge" src="static/images/peri-perks/google-play-badge.svg" />
              </Link>
            </Flex>

            <Subheading variant={3}>Earn points</Subheading>

            <P variant={2} mt={1} mb={1}>
              Every single dollar you spend through your account adds another Perks Point to your stash. We’ll even
              round up to the nearest dollar because we love being extra.
            </P>

            <Subheading variant={3}>Get rewarded</Subheading>

            <P variant={2} mt={1} mb={1}>
              Turn your points into free Nando’s food, unique merch and much more!
            </P>

            <P variant={3} color="greyPrimary">
              Make sure you stay opted in to receive communications from us to gain access to all the Perks mentioned
              above, including your freebies and invites to our exclusive member events.
            </P>
          </Flex>
        </Flex>

        <Flex width={{ xs: 12 / 12, lg: 6 / 12 }} height="fit-content">
          <AppPreview alt="app-preview" src="static/images/peri-perks/app-preview.png" />
        </Flex>
      </Flex>
    </Container>
  );
};

const AppPreview = styled.img`
  width: 700px;

  @media (min-width: ${breakpoints.md}) {
    max-width: initial;
  }
`;

const AppBadge = styled.img`
  height: 40px;
  margin-right: 0.5rem;

  @media (min-width: ${breakpoints.md}) {
    height: 54px;
  }
`;

PeriPerksAppStore.propTypes = {
  showCreateAccountLink: PropTypes.bool,
};

PeriPerksAppStore.defaultProps = {
  showCreateAccountLink: true,
};

export { PeriPerksAppStore };
