import get from 'lodash/get';
import gql from 'graphql-tag';

import { getApiClient } from '../../../lib/api-client';

const verifyMutation = gql`
  mutation verify($input: VerificationInput!) {
    verify(input: $input) {
      success
    }
  }
`;

const handleRegistrationCallback = async code => {
  try {
    const client = await getApiClient();
    await client.mutate({
      mutation: verifyMutation,
      variables: { input: { code } },
    });
    return { success: true };
  } catch (error) {
    return { success: false, errorMessage: get(error, 'errors[0].message', error.message) };
  }
};

export { handleRegistrationCallback };
