import get from 'lodash/get';
import gql from 'graphql-tag';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import toLower from 'lodash/toLower';

import { ContentMatrix } from '../../components/content-matrix';
import { extractSeoMetaFromEntry } from '../extract-seo-meta-from-entry';
import { fetch } from '../../content/queries';
import { floodlightQuery } from '../../content/query-snippets';
import { logger } from '../logger';

const fetchCMSContent = async ({ uri, child, slug, preview, token }) => {
  const contentQuery = gql`
    query getEntryBySlugFromCMS {
      entry(siteId: "${process.env.cms.siteId}", slug: "${toLower(child || slug)}") {
        ... on pages_pages_Entry {
          title
          id
          ${ContentMatrix.query}
          ${floodlightQuery}
          parent {
            title
            slug
          }
        }
      }
      seomatic (siteId: ${process.env.cms.siteId}, uri: "${toLower(uri)}", asArray: true) {
        metaTitleContainer
        metaTagContainer
        metaLinkContainer
        metaJsonLdContainer
      }
    }
  `;

  let data;
  try {
    data = await fetch({
      query: contentQuery,
      headers: omitBy({ 'x-craft-live-preview': preview, token }, isUndefined),
    });
  } catch (err) {
    logger.error('CMS: Failed to fetch content from CMS', err);
  }

  const id = get(data, 'entry.id');
  const title = get(data, 'entry.title');
  const contentMatrix = get(data, 'entry.contentMatrix', []);
  const parent = get(data, 'entry.parent');
  const floodlightPageLoadEvent = get(data, 'entry.floodlightPageLoadEvent[0]');
  const metaContent = extractSeoMetaFromEntry(data);

  return { id, title, contentMatrix, metaContent, parent, floodlightPageLoadEvent };
};

export { fetchCMSContent };
